.selectedDatesBarContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;

  .selectedDatesBarButtons {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    gap: 10%;

    width: 100%;
    //background-color: red;
    .addEventButtonNotSelected {
      color: var(--grey-text);
      border-radius: 4px;
      padding: 0.375rem 1rem;
      cursor: pointer;
      border: 1px solid var(--grey-background);
      background: none;
    }

    .addEventButtonNotSelected:hover {
      box-shadow: 0 0 15px 4px var(--light-wolf);
      background-color: var(--grey-hover);
      border-color: var(--wolf);

    }
  }
}
.rbc-event{
  padding: 1px;
  width: 100%;
  background-color: white;
}
.calenderContainer{
  font-size: 8px;
  border-radius: 4%;
  align-items: center;
  text-align: center;
  .descriptionStyle{
    text-decoration: underline;
    font-weight: 600;
  }
}


@media only screen and (min-width: 1450px) {
  .calenderContainer{
    font-size: 14px;
  }
}
@media only screen and (min-width: 1919px) {
  .calenderContainer{
    font-size: 16px;
  }
}



.fullCalendarWidth{
  width: 100%;
}
.notFullCalendarWidth{
  width: calc(100% - 400px);
}

.week-calender-wrapper{
  font-size: 9px;
  width: 100%;
}
.month-calender-wrapper{
  font-size: 9px;
  height: 600px;
  width: 100%;
}
.agenda-calender-wrapper{
  font-size: 14px;
}
@media only screen and (min-width: 1450px) {
  .month-calender-wrapper{
    font-size: 14px;
  }
  .week-calender-wrapper{
    font-size: 14px;
  }
}
@media only screen and (min-width: 1919px) {
  .month-calender-wrapper{
    font-size: 16px;
  }
  .week-calender-wrapper{
    font-size: 16px;
  }
}




.week-calender-wrapper .rbc-time-content {
  display: none;
}
.rbc-toolbar{
  gap: 10px;
}
.rbc-label {
  display: none;
}
.iconWrapperOfRoll{
  --icon-color: #5A84C4;
}
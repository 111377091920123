.clientEventDetails{
  padding-block: 20px;
  padding-inline: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .closeClientEventDetails{
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding: 1px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
   &:hover{
     background-color:var(--grey-hover);

   }
  }
.descriptionLabel{
  font-size: 28px;
  font-weight: 700;
  display: flex;
  justify-content: center;
}
  .eventDetailsText{
    font-size: 20px;
    display: flex;
    justify-content: end;
  }
  .setAvailabilityText{
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
    text-align: end;
  }
  .iconsWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

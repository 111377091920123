.addManualEmployeeContainer{
  width: 100%;


  .bookedEmployeesFromListBtn{
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    &:hover{
      background-color: var(--grey-active) !important;
      box-shadow: 0 0 20px 1px var(--dark) !important;
    }
  }


}
.employeeDialogContainer{
  padding: 20px;
  //width: 100%;
  .closeX{
    width: 15px;
    height: 15px;
    display: flex;
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    &:hover{
      background-color: var(--light-wolf);
    }
  }
  .dialogHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}
.eventUserList{
  display: flex;
  &:hover{
    background-color: var(--grey-hover);
    cursor: pointer;
  }
  .arrowDropDown{
    --icon-color: var(--primary);
  }
}

.side-modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  right: 0;
  padding: 18px 16px;
  width: 400px;
  min-height:96%;
  height: 90%;
  z-index: 320;
  background: #fafbfc;
  overflow: hidden;
  filter: drop-shadow(0px 15px 50px rgba(0, 0, 0, 0.3));
}

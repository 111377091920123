.headerContainer{
background-color: var(--primary);
.loginWrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;


  .formWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .inputWrapper {
      display: flex;

      .labelStyle {
        color: var(--white);
        font-size: 28px;
        font-style: italic;
        font-weight: 400;
        width: 150px;
        height: 30px;
      }

      .inputText {
        border: 0;

        &:hover {
          box-shadow: 0 0 15px 4px var(--dark);
        }

        border-radius: 8px;
        padding-inline: 8px;
      }
    }

    .buttonStyle {
      height: 35px;
      border-radius: 8px;
      border: 0;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 15px 4px var(--dark);
      }
    }
  }
}}
.forgetPassword{
  color: var(--white);
  cursor: pointer;
  font-weight: 600;
}
.versionNumStyle{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Russo One Regular"!important;
  font-size: 12px;
}
.errorIcon{
  width: 50px;
  height: 50px;
  --icon-color: var(--alert);
}
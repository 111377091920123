.employeeListContainer{
  padding-inline: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .employeeListLabels{
   display: flex;
    gap: 30px;
    font-weight: 700;
    border-bottom: 1px solid var(--dark);
    padding-bottom: 10px;
  }
  .column1{
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

  }

.tableBody{
  display: flex;
  gap:30px;
  padding-block: 5px;
  align-items: center;
  justify-content: center;

  &:hover{
    background-color: var(--light-wolf);
  font-weight: 600;
  }
}

  .actionsStyle{
    display: flex;
    justify-content: space-between;
 .iconLabel{
   display: flex;
   gap: 4px;
   align-items: center;
   cursor: pointer;
 }

    .editStyle:hover{
      --icon-color: var(--primary);
      color: var(--primary);

    }
    .deleteStyle:hover{
      color: var(--alert);
      --icon-color: var(--alert);
    }



    .checkWrapper{
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      --icon-color: var(--primary);
      border-radius: 50%;
      padding: 2px;
      width: 20px;
      height: 20px;
      background-color: var(--grey-hover);
    }
  }
}
.dialogContainer{
  padding: 20px;
  .closeX{
    width: 15px;
    height: 15px;
    display: flex;
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    &:hover{
      background-color: var(--light-wolf);
    }
  }
}

.mainPanelContainer {
  height: 100%;
  display: flex;
  flex-direction: column;

  .companyName{
    font-family: "Russo One Regular"!important;
    display: flex;
    justify-content: center;
    font-size: 18px;
    padding-block: 2px;
    box-shadow: 0 0 15px 4px var(--light-wolf);

    font-weight: 300;
     background-color:var(--grey-hover);
  }
  .mainPanelBody {
    padding-inline: 1%;
  }
  .userNameStyle{
    font-weight: 600;
  }
  @media only screen and (min-width: 1450px) {
    .mainPanelBody{
      padding-inline: 5%;
    }
  }
  @media only screen and (min-width: 1919px) {
    .mainPanelBody{
      padding-inline: 5%;
    }
  }

.menuContainer{
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 10px;
  margin-top: 5px;
  border: 1px solid var(--light-wolf);
  background-color: var(--grey-hover);
  .menuWrapper{

    //height: 30px;
    //width: 30px;
    //background-color: var(--grey-hover);
  }
}
  .menuItems{
    border: 1px solid var(--light-wolf);

    width: 80px;
    background-color: var(--grey-hover);
    margin-left: 10px;
    padding: 8px;
  }

}

.addEventButtonWrapper {
  margin-block: 30px;
  display: flex;
  gap: 10px;

  .addEventButtonSelected {
    color: var(--white);
    background-color: var(--primary);
    border-radius: 4px;
    padding: 0.375rem 1rem;
    cursor: pointer;
    border: solid 1px var(--wolf);
    box-shadow: 0 0 15px 4px var(--light-wolf);

    &:hover {
      background-color: var(--primary);
    }
  }

  .addEventButtonNotSelected {
    color: var(--grey-text);
    border-radius: 4px;
    padding: 0.375rem 1rem;
    cursor: pointer;
    border: 1px solid var(--grey-background);
    background: none;
  }

  .addEventButtonNotSelected:hover {
    box-shadow: 0 0 15px 4px var(--light-wolf);
    background-color: var(--grey-hover);
    border-color: var(--wolf);

  }
}

.container {
  font-family: "Russo One Regular" !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--primary);
  align-items: center;
  gap: 3px;
  padding-block: 8px;
  font-style: italic;
  font-weight: bold;

  .ourLuzIconWrapper{
    display: flex;
    flex: 1;
    width: 50px;
  }
.headerTextWrapper{
  flex: 4;
display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .mainHeader {
    color: var(--white);
    font-size: 28px;
  }

  .secondaryHeader {
    color: var(--wolf);
    font-size: 20px;
  }
}
  .avatarContainer{
    padding-inline-end: 12px;
    justify-content: end;
    display: flex;
    flex: 1;
  }
}

:root {
  --primary: #1A96E7;
  //--primary: #2B76E5;
  --primary-hover: #0747A6;
  --alert: #BF2600;
  --attention: #FFEBE6;
  --private: #172B4D;
  --dark: #383838;
  --dark-grey: #5B5C60;
  --wolf: #C5C7D0;
  --riverstone: #F5F6F8;
  --white: #FFFFFF;
  --light-wolf: #c3c6d4;
  --navy: #225091;
  --light-green: #32B67A;
  --dark-green: #175A63;
  --dark-indigo: #401694;
  --grey-winter: #9AADBD;
  --pecan: #563E3E;
  --light-blue: #579BFC;
  --river: #68A1BD;
  --green: green; //#037F4C
  --indigo: #5559DF;
  --steel: #A9BEE8;
  --brown: #7F5347;
  --dark-sky: #49799C;
  --explosive: #C4C4C4;
  --turquoise: #0F8ABA;
  --berry: #7E3B8A;
  --chillblue: #0086C0;
  --blackish: #333333;
  --sky-blue: #66CCFF;
  --purple: #A25DDC;
  --mint-green: #01B698;
  --soil: #AC6F37;
  --tan: #AD967A;
  --grey: #B8B8B8;
  --baby-blue: #A1E3F6;
  --american-gray: #808080;
  --fern-green: #8ABAB1;
  --vivid--blue: #3A86FF;
  --pine-green: #098281;
  --lilac: #9D99B9;
  --lavender: #BDA8F9;
  --ui-grey: #E6E9EF;
  --secondary-blue: #CCE5FF;
  --dark-yellow: #FFC400;
  --negative-color-selected: #F4C3CB;
  --grey-active: #F1F5FF;
  --succsess-green: #258750;
  --grey-text:#373a3c;
  --grey-background:#ccc;
  --grey-hover:#e6e6e6;
}


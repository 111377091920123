.operation-details-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  --icon-color: var(--dark);
  padding-bottom: 20px;
  border-bottom: 1px solid var(--ui-grey);
  margin-bottom: 20px;

  .header-text {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .exitIconWrapper{
    align-items: center;
    display: flex;
    cursor: pointer;
    padding: 3px;
    &:hover{
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      background-color: var(--grey-hover);
      border-color: var(--wolf);
    }

  }
}

.formBodyContainer {
  width: 100%;
  height: 100%;

  .formContainer {
    width: 100%;
    height: 100%;

    .fieldWrapper {
      padding-top: 10px;
      height: 85%;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 15px;
    }


    .bottomButtonsWrapper {
      display: flex;
      justify-content: space-between;

      .submitButton {
        margin-top: 20px;
      }
    }
  }
}

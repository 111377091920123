.subTitleContainer{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;


.greyLine{
  height: 1px;
  width: 100%;
  background-color: var(--ui-grey);
}}
.newPasswordContainer{
  width: 100%;
  height: 100%;
}
.newPasswordWrapper {
  width: 100%;
  height: 100%;

  .fieldWrapper {
    padding-top: 10px;
    height: 85%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 15px;
  }
}
.pin-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin-top: 10%;
}

.pin-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  outline: none;
}
